import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "../layout/Index";
import Dashboard from "../pages/Dashboard";
import QrCodePage from "../pages/QrCodePage";
import LayoutNoSideBar from "../layout/Index-nosidebar";
import PDFViewer from "../pages/PDFViewer";
import LayoutSidebar from "../layout/index-sidebar";
import QrCodeCollection from "../pages/QrCodeCollection";
import QrCodeCmsNew from "../pages/QrCodeCmsNew";
import LayoutAPI from "../layout/index-apipage";
import QrCodeGenerator from "../pages/QrCodeGenerator";
import { SubscriptionContextProvider } from "../pages/SubscriptionContext";
import Products from "../pages/Products";
import ProductQRCode from "../pages/Allproducts";
import Existingproducts from "../pages/Existingproduct";
import Newproducts from "../pages/Newproduct";
import SeachHoempage from "../pages/SeachHoempage";
import Sku from "../pages/SKU";
import Pdf from "../pages/PDF";
import Website from "../pages/Website";
import { io } from "socket.io-client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getCookie } from "../utils/Utils";
const queryClient = new QueryClient();

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const username = getCookie("username");
  const moduleName = process.env.REACT_APP_MODULE_NAME;
  const socket = io("https://live.silocloud.io");
  useEffect(() => {
    socket.on(`notification_${moduleName}_${username}`, (data) => {
      console.log("Notification received:", data);
      console.log(data);
      if (data) {
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: "https://api.silocloud.io/logo/apps/silo-qr.png",
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: "https://api.silocloud.io/logo/apps/silo-qr.png",
                });
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    return () => {
      socket.off(`notification_${moduleName}_${username}`);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSideBar />}>
          <Route path="pdf/:id" element={<PDFViewer />}></Route>
          <Route path="website/:id" element={<Website />} />
        </Route>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutAPI />}>
          <Route path="v1/create-qr-code/:userid" element={<QrCodeGenerator />} />
        </Route>
        <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
          <Route element={<SubscriptionContextProvider />}>
            <Route path="dashboard" element={<Dashboard />}></Route>
            <Route index element={<QrCodePage />}></Route>
            <Route path="serachpage" element={<SeachHoempage />} />
          </Route>
        </Route>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutSidebar />}>
          <Route element={<SubscriptionContextProvider />}>
            <Route path="collection/all" element={<QrCodeCollection />} />
            <Route path="collections" element={<QrCodeCmsNew />} />
            <Route path="products" element={<Products />} />
            <Route path="products/addproducts" element={<ProductQRCode />} />
            <Route path="existingproducts" element={<Existingproducts />} />
            <Route path="newproducts" element={<Newproducts />} />
            <Route path="sku" element={<Sku />} />
            <Route path="pdf" element={<Pdf />} />
          </Route>
        </Route>
      </Routes>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};
export default Pages;
