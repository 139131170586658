import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import FourteenDaysCountDown from "../pages/hooks/FourteenDaysCountDown";
export const GloabalContext = createContext();
const Layout = ({ title, app, ...props }) => {
  const [files, setFiles] = useState([]);
  const [authChecking, setAuthChecking] = useState(true);
  const endDate = new Date("2024-05-30 09:38:09"); // Replace with your desired end date
  const { days, hours, minutes, seconds } = FourteenDaysCountDown(endDate);
  const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
  // useEffect(() => {
  //   let at = Cookies.get("authToken");
  //   if (!at) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });
  //     window.location = process.env.REACT_APP_ACCOUNT_URL;
  //   } else {
  //     setAuthChecking(false);
  //     Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
  //   }
  // }, []);
  // if (authChecking) return <>Redirecting to authentication</>;
  useEffect(() => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  }, []);
  return (
    <>
      <GloabalContext.Provider value={{ files, setFiles }}>
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps apps-only">
          <AppMain>
            {/* <Sidebar fixed /> */}
            <AppWrap>
              <Header fixed />
              <ToastContainer hideProgressBar autoClose={3000} />
              <Outlet />
              <Footer fixed />
              {/* <MobileFooter /> */}
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
