import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Icon } from "../components/Component";
import { getCookie } from "../utils/Utils";

function EmbededCode({ qr_id, modalZoom, toggleZoom }) {
  // Copy to clipboard functionality
  const [buttonText, setButtonText] = useState("Copy");
  const [copyicon, setCopyIcon] = useState("copy");

  const copyIframeCode = () => {
    navigator.clipboard
      .writeText(`<img src="${process.env.REACT_APP_API_URL}api/v1/public/get-qr-image/${qr_id}" alt="Silocloud QR">`)
      .then(() => {
        setButtonText("Copied");
        setCopyIcon("check-thick");
        setTimeout(() => {
          setButtonText("Copy");
          setCopyIcon("copy");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy iframe code: ", err);
      });
  };

  return (
    <>
      <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom" size="lg">
        <ModalHeader
          className="border-0"
          toggle={toggleZoom}
          close={
            <button className="close" onClick={toggleZoom}>
              <Icon name="cross" />
            </button>
          }
        >
          <span className="customfont">
            <Icon name="code" className="me-1" /> Embed
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <Button className={`btn bg-white text-dark border-white changecodenav shadow`} color="primary">
              <Icon name="html5" />
              <span className="customfont text-orange">HTML 5</span>
            </Button>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-end">
              <Button
                className="btn bg-white shadow text-dark border-white btn-sm"
                color="primary"
                onClick={copyIframeCode}
              >
                <Icon name={copyicon} className={copyicon === "check-thick" ? "text-success" : ""} />
                <span className="customfont text-orange">{buttonText}</span>
              </Button>
            </div>
            <div className="mt-2 p-2 customcodeblock embededcodescroll">
              <code className="fw-bold">
                &lt;img src="{`${process.env.REACT_APP_API_URL}api/v1/public/get-qr-image/${qr_id}`}" alt="Silocloud QR"
                /&gt;
              </code>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default EmbededCode;
